<template>
  <!-- Uses notificationId as key to force a full update on sub components on route change -->
  <v-container
    :key="notificationId"
    class="pa-0"
    style="overflow-y: auto; height: 100%; width: 100%"
    fluid
  >
    <v-card
      v-if="expireAt"
      color="warn lighten-4"
      tile
      data-cy="notification-trash-message"
      class="d-flex flex-column align-center"
      elevation="0"
      min-height="68"
    >
      <v-card-title
        class="warn--text"
        style="word-break: auto-phrase;"
      >
        {{ expireMode }}
      </v-card-title>
      <v-card-text
        class="d-flex align-center justify-center"
      >
        <v-icon
          class="mr-2"
          color="warn"
        >
          {{ icons.alertCircle }}
        </v-icon>
        <span class="warn--text text-body-2 text-sm-body-1">
          {{
            $t(
              'deconve.notification.notificationInTrashMessage',
              [$moment(expireAt).format('DD/MM/YYYY HH:mm:ss')]
            )
          }}h
        </span>
      </v-card-text>
    </v-card>

    <div class="pa-2">
      <confirmation-dialog ref="confirm" />
      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp"
        left
        relative
        elevation="0"
        icon
        color="neutral"
        @click="goBack"
      >
        <v-icon> {{ icons.chevronLeft }} </v-icon>
      </v-btn>

      <v-form
        ref="form"
        v-model="valid"
        :class="$vuetify.breakpoint.md ? 'mt-8': 'mt-2'"
      >
        <v-container
          fluid
          style="max-width: 1800px"
        >
          <v-col>
            <v-row class="mb-2">
              <v-col
                cols="6"
                class="d-flex align-center"
              >
                <v-row
                  v-if="!isLoadingNotification || isSamePerson"
                  class="mb-n2"
                >
                  <div class="d-flex align-center">
                    <rectangle-button
                      :disabled="
                        !$can('update', 'com.deconve.faceid.notification')
                          || (isLoadingNotification || expireAt !== '')"
                      :class="isSamePersonVerifier && isSamePersonVerifier !== undefined ?
                        'positiveButtonBackgroundColor disablePointEvents' : 'none'"
                      :icon="isSamePersonVerifier && isSamePersonVerifier !== undefined
                        || $vuetify.breakpoint.mobile? 'mdi-check' : ''"
                      :color="isSamePersonVerifier && isSamePersonVerifier !== undefined ?
                        'success' : 'neutral'"
                      outlined
                      @clicked="updateIsSamePerson('yes')"
                    >
                      <div class="d-none d-md-flex">
                        {{ $t('deconve.isSamePerson') }}
                      </div>
                    </rectangle-button>
                    <rectangle-button
                      :disabled="
                        !$can('update', 'com.deconve.faceid.notification')
                          || (isLoadingNotification || expireAt !== '')"
                      class="ml-2"
                      :class="!isSamePersonVerifier && isSamePersonVerifier !== undefined ?
                        'negativeButtonBackgroundColor disablePointEvents' : 'none'"
                      :icon="!isSamePersonVerifier && isSamePersonVerifier !== undefined
                        ||$vuetify.breakpoint.mobile? 'mdi-close' : ''"
                      :color="!isSamePersonVerifier && isSamePersonVerifier !== undefined
                        ? 'warn' : 'neutral'"
                      outlined
                      @clicked="updateIsSamePerson('no')"
                    >
                      <div class="d-none d-md-flex">
                        {{ $t('deconve.isNotSamePerson') }}
                      </div>
                    </rectangle-button>
                    <rectangle-button
                      v-if="canMonitorNotification"
                      :color="'neutral'"
                      :icon="$vuetify.breakpoint.mobile? 'mdi-eye-plus-outline' : ''"
                      :disabled="
                        !$can('update', 'com.deconve.faceid.notification')
                          || (isLoadingNotification || expireAt !== '')"
                      class="ml-7"
                      outlined
                      @clicked="followNotification"
                    >
                      <div class="d-none d-md-flex">
                        {{ $t('deconve.monitorNotification') }}
                      </div>
                    </rectangle-button>
                  </div>
                </v-row>
                <v-row
                  v-else
                  class="mb-2"
                >
                  <v-skeleton-loader
                    type="heading"
                    style="flex: 1"
                    max-width="700"
                  />
                </v-row>
              </v-col>
              <v-col
                cols="6"
                class="py-4"
              >
                <v-row class="d-flex flex-nowrap justify-end align-center">
                  <square-button
                    icon-name="mdi-dots-horizontal"
                    icon-color="neutral"
                    data-cy="notification-settings"
                    color="transparentBackground"
                    outlined
                  >
                    <v-list
                      dense
                      elevation="0"
                    >
                      <v-list-item
                        data-cy="notification-delete"
                        :disabled="!$can('delete', 'com.deconve.faceid.notification')"
                        @click="deleteNotificationDialog"
                      >
                        {{ $t('deconve.delete') }}
                      </v-list-item>
                      <v-list-item
                        v-if="expireAt"
                        data-cy="notification-restore-from-trash"
                        :disabled="!$can('update', 'com.deconve.faceid.notification')"
                        @click="restoreNotificationFromTrash"
                      >
                        {{ $t('deconve.restoreFromTrash') }}
                      </v-list-item>
                      <v-list-item
                        v-else
                        data-cy="notification-move-to-trash"
                        :disabled="!$can('update', 'com.deconve.faceid.notification')"
                        @click="moveNotificationToTrash"
                      >
                        {{ $t('deconve.moveToTrash') }}
                      </v-list-item>
                      <v-list-item
                        @click="openNotificationsReviewDialog"
                      >
                        {{ $t('deconve.muteNotifications') }}
                      </v-list-item>
                      <v-list-item
                        :disabled="!$can('read', 'com.deconve.faceid.person') || !person"
                        @click="goToProfile"
                      >
                        {{ $t('deconve.notification.seeProfile') }}
                      </v-list-item>
                    </v-list>
                  </square-button>
                </v-row>
              </v-col>
            </v-row>
            <v-row style="max-width: 1775px">
              <notification-image-viewer
                :notification-images="notificationImages"
                :video-name="videoName"
                :person-name="personName"
                :created-at="createdAt"
                :index="imageIndex"
                :profile-image="personProfileImage"
                :number-of-notification-images="numberOfImages"
                :notification-loading="isLoadingNotification || isLoadingPerson"
                :profile-image-index="selectedProfileImageIndex"
                :shared-person="isASharedPersonProfile"
                @selectedNotificationImageIndex="setNotificationImageIndex"
              />
            </v-row>
          </v-col>

          <v-col class="mt-6">
            <v-row
              v-if="!isLoadingPerson"
              class="mb-2"
              align-content="center"
            >
              <v-icon
                class="mr-2"
                color="textBlack"
                :size="$vuetify.breakpoint.mobile? 20 : 24"
              >
                {{ icons.imageMultiple }}
              </v-icon>
              <div class="subtitle-1 text-sm-h6 font-weight-bold textBlack--text">
                {{ $t('deconve.notification.profileImages') }}
              </div>
            </v-row>
            <v-row
              v-else
              class="mb-2"
            >
              <v-skeleton-loader
                type="heading"
                style="flex: 1"
                max-width="500"
              />
            </v-row>

            <image-list
              v-if="!isASharedPersonProfile"
              :is-edit-mode="false"
              :score-status="true"
              :is-loading="isLoadingPerson"
              :images="personImages"
              @set-crop-image="selectProfileImage"
            />
          </v-col>

          <v-col
            class="mt-6"
          >
            <v-row
              v-if="!isLoadingPerson"
              class="mb-n2"
            >
              <h5
                v-if="personName"
                class="text-h6 text-sm-h5 font-weight-bold textBlack--text"
              >
                {{ personName }}
              </h5>
              <h5
                v-else-if="isASharedPersonProfile"
                class="text-h6 text-sm-h5 font-weight-bold primary--text"
              >
                {{ $t('deconve.deconveProfile') }}
              </h5>
              <h5
                v-else
                class="text-h6 text-sm-h5 font-weight-bold error--text"
              >
                {{ $t('deconve.idNotFound', [personId]) }}
              </h5>
            </v-row>
            <v-row
              v-else
              class="mb-2"
            >
              <v-skeleton-loader
                type="heading"
                style="flex: 1"
                max-width="500"
              />
            </v-row>
            <v-row class="mb-4">
              <div class="d-flex flex-wrap">
                <tag
                  v-for="tag in personTags"
                  :key="tag.id"
                  class="pa-1"
                  :tag-id="tag.id"
                  link="faceidnotifications"
                />
              </div>
            </v-row>
            <v-row
              v-if="personName && personAbout"
              class="mb-2 neutral--text body-2 text-sm-body-1"
            >
              {{ personAbout }}
            </v-row>
            <v-row
              v-if="isLoadingPerson"
              class="mb-2"
            >
              <v-skeleton-loader
                type="paragraph"
                style="flex: 1"
                max-width="500"
              />
            </v-row>
            <v-row>
              <rectangle-button
                v-if="!isLoadingPerson"
                color="neutral"
                outlined
                :disabled="!$can('read', 'com.deconve.faceid.person') || !person"
                @clicked="goToProfile"
              >
                {{ $t('deconve.notification.seeProfile') }}
              </rectangle-button>
              <v-skeleton-loader
                v-else
                type="chip"
                tile
              />
            </v-row>
          </v-col>

          <v-col class="mt-6">
            <v-row
              v-if="!isLoadingPerson"
              class="d-flex align-center"
            >
              <v-icon
                class="mr-2"
                color="textBlack"
                :size="$vuetify.breakpoint.mobile? 24 : 28"
              >
                {{ icons.accountMultiple }}
              </v-icon>

              <div class="subtitle-1 text-sm-h6 font-weight-bold textBlack--text">
                {{ $tc('deconve.relatedProfilesNumber', personRelatedProfiles.length) }}
              </div>

              <square-button
                v-if="personRelatedProfiles.length > 0"
                menu-disabled
                color="neutralPrimary"
                icon-color="neutralPrimary"
                :icon-name="!isToHideRelatedProfiles? 'mdi-chevron-down' : 'mdi-chevron-up'"
                @clicked="isToHideRelatedProfiles = !isToHideRelatedProfiles"
              />
            </v-row>
            <v-row
              v-else
              class="mb-2"
            >
              <v-skeleton-loader
                type="heading"
                style="flex: 1"
                max-width="500"
              />
            </v-row>
            <v-row v-if="!isToHideRelatedProfiles">
              <div
                style="width: 100%"
                class="mt-2"
              >
                <profile-list-viewer
                  :profiles-to-show="personRelatedProfiles"
                  :is-loading="isLoadingPerson"
                  is-related-profiles
                />
              </div>
            </v-row>
          </v-col>

          <template />

          <v-col class="mt-6">
            <v-row
              v-if="!isLoadingNotification"
              class="mb-6"
            >
              <v-icon
                class="mr-2"
                color="textBlack"
                :size="$vuetify.breakpoint.mobile? 24 : 28"
              >
                {{ icons.bellOutline }}
              </v-icon>
              <div class="subtitle-1 text-sm-h6 font-weight-bold textBlack--text">
                {{ $t('deconve.notification.details') }}
              </div>
            </v-row>
            <v-row
              v-else
              class="mb-2"
            >
              <v-skeleton-loader
                type="heading"
                style="flex: 1"
                max-width="500"
              />
            </v-row>
            <v-row>
              <v-textarea
                v-if="!isLoadingNotification"
                v-model="notificationAbout"
                :counter="maxAboutTextLength"
                :rules="aboutRules"
                height="100"
                no-resize
                class="body-2 text-sm-body-1"
                :label="$t('deconve.about')"
                :readonly="!isEdit"
                :disabled="
                  !$can('update', 'com.deconve.faceid.notification')
                    || (expireAt !== '')
                "
                outlined
                @click="isEdit=true"
                @blur="isEdit=false"
              />
              <v-skeleton-loader
                v-else
                type="paragraph"
                style="flex: 1"
                max-width="500"
              />
            </v-row>
            <v-row class="mt-6 d-flex justify-end">
              <rectangle-button
                v-if="!isLoadingNotification || !isEditingNotification"
                color="primary"
                data-cy="notification-submit"
                :disabled="
                  !$can('update', 'com.deconve.faceid.notification') || (!valid || !hasChanged)
                "
                @clicked="submitNotification"
              >
                {{ $t('deconve.submit') }}
              </rectangle-button>
              <v-skeleton-loader
                v-else
                type="chip"
                tile
              />
              <v-snackbar
                v-model="alert"
                :color="alertColor"
              >
                {{ alertMessage }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    text
                    v-bind="attrs"
                    @click="resetAlert"
                  >
                    <v-icon color="white">
                      {{ icons.close }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-snackbar>
            </v-row>
          </v-col>

          <v-row
            v-if="!isLoadingNotification"
            class="mb-6 mt-6 mx-0 d-flex justify-space-between align-center"
          >
            <div class="d-flex flex-row">
              <v-icon
                class="mr-2"
                color="textBlack"
                :size="$vuetify.breakpoint.mobile? 24 : 28"
              >
                {{ icons.history }}
              </v-icon>
              <div class="subtitle-1 text-sm-h6 font-weight-bold textBlack--text">
                {{ $t('deconve.history') }}
              </div>
            </div>
            <square-button
              menu-disabled
              color="neutralPrimary"
              icon-color="neutralPrimary"
              :icon-name="isToListHistory? 'mdi-chevron-down' : 'mdi-chevron-up'"
              @clicked="isToListHistory = !isToListHistory"
            />
          </v-row>
          <div v-if="!isLoadingNotification && isToListHistory">
            <history-item
              v-for="(item, i) in notificationHistory"
              :key="i"
              :action="item.action"
              :created-by="item.created_by"
              :created-at="item.created_at"
            />
          </div>
        </v-container>
      </v-form>
    </div>
    <notifications-review-dialog ref="notificationsReviewDialog" />
  </v-container>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import { downloadImage as downloadImageHelper } from '@/api';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import SquareButton from '@/components/SquareButton.vue';
import RectangleButton from '@/components/RectangleButton.vue';
import ImageList from '@/components/ImageList.vue';
import Tag from '@/components/Tag.vue';
import HistoryItem from '@/components/HistoryItem.vue';
import NotificationsReviewDialog from '@/components/NotificationsReviewDialog.vue';
import NotificationImageViewer from '@/components/NotificationImageViewer.vue';
import ProfileListViewer from '@/components/ProfileListViewer.vue';
import {
  mdiAccountMultipleOutline, mdiAlertCircleOutline,
  mdiBellOutline, mdiChevronLeft, mdiClose, mdiHistory,
  mdiImageMultipleOutline,
} from '@mdi/js';

export default {
  components: {
    ConfirmationDialog,
    NotificationsReviewDialog,
    SquareButton,
    RectangleButton,
    ImageList,
    NotificationImageViewer,
    Tag,
    HistoryItem,
    ProfileListViewer,
  },
  data() {
    return {
      isToListHistory: true,
      alert: false,
      alertMessage: '',
      alertColor: '',
      fromRoute: null,
      notificationAbout: '',
      notificationId: '',
      personProfileImage: undefined,
      isEditingNotification: false,
      isEdit: false,
      valid: true,
      numberOfImages: 0,
      notificationImages: [],
      profileImages: [],
      notificationImageIndex: null,
      bestFaceIndex: 0,
      selectedProfileImageIndex: 0,
      isSamePerson: '',
      maxAboutTextLength: 600,
      notification: undefined,
      person: undefined,
      video: undefined,
      isToHideRelatedProfiles: true,
      icons: {
        accountMultiple: mdiAccountMultipleOutline,
        bellOutline: mdiBellOutline,
        alertCircle: mdiAlertCircleOutline,
        chevronLeft: mdiChevronLeft,
        history: mdiHistory,
        imageMultiple: mdiImageMultipleOutline,
        close: mdiClose,
      },
    };
  },
  computed: {
    ...mapGetters({
      getNotificationHistory: 'faceid/getNotificationHistory',
      getNotification: 'faceid/getNotification',
      personImages: 'faceid/personImages',
      getVideo: 'faceid/getVideo',
    }),
    notificationRef() {
      return this.getNotification(this.$route.params.notificationId);
    },
    personId() {
      if (!this.notification) return undefined;

      return this.notification.person.id;
    },
    createdAt() {
      if (!this.notification) return '';

      return `${this.$moment(this.notification.created_at).format('DD/MM/YYYY HH:mm:ss')}h`;
    },
    expireAt() {
      return this.notification?.expire_at || '';
    },
    expireMode() {
      switch (this.notification?.expire_mode) {
        case 'deleted by user':
          return this.$t('deconve.notification.expireMode.deletedByUser');
        case 'deleted by review status':
          return this.$t('deconve.notification.expireMode.deletedByReviewStatus');
        case 'person deleted':
          return this.$t('deconve.notification.expireMode.personDeleted');
        default:
          return '';
      }
    },
    visibleTime() {
      if (!this.notification) return '';

      const { visible_time_ms: visibleTimeMs } = this.notification;

      if (!visibleTimeMs || visibleTimeMs <= 0) return '';

      const visibleTimeSeconds = Math.ceil(visibleTimeMs / 1000.0);

      return this.$t('deconve.notification.visibleTimeSeconds', [
        visibleTimeSeconds,
      ]);
    },
    personAbout() {
      return this.person?.about || '';
    },
    personName() {
      return this.person?.name || '';
    },
    personTags() {
      return this.person?.tags || [];
    },
    personRelatedProfiles() {
      return this.person?.related_profiles || [];
    },
    videoName() {
      return this.video?.name || '';
    },
    aboutRules() {
      return [
        (v) => (!v || (v && v.length <= this.maxAboutTextLength))
          || this.$t('deconve.inputRules.about', [this.maxAboutTextLength]),
      ];
    },
    isLoadingNotification() {
      return this.notification === undefined;
    },
    isLoadingPerson() {
      return this.person === undefined;
    },
    hasChanged() {
      return this.notification && this.notification?.about !== this.notificationAbout;
    },
    notificationHistory() {
      const history = this.getNotificationHistory(this.notificationId);

      if (history) {
        this.sortHistoryInDescendingOrder(history);
        return history;
      }

      return [];
    },
    tags() {
      return this.notification?.tags || [];
    },
    isSamePersonVerifier() {
      switch (this.isSamePerson) {
        case 'yes':
          return true;
        case 'no':
          return false;
        default:
          return undefined;
      }
    },
    isASharedPersonProfile() {
      if (this.notification) {
        const { is_from_a_shared_person: sharedPerson } = this.notification;

        return !this.$can('use', 'com.deconve.hub') && sharedPerson;
      }

      return false;
    },
    canMonitorNotification() {
      if (this.notification) {
        const { is_same_person: isSamePerson, monitored_at: monitoredAt } = this.notification;

        return isSamePerson === 'yes' && !monitoredAt;
      }

      return false;
    },
    imageIndex() {
      return this.notificationImageIndex > 0
        ? this.notificationImageIndex : 0;
    },
    isOnDeconveHubWorkspace() {
      return this.$can('use', 'com.deconve.hub');
    },
  },
  watch: {
    notification() {
      this.onNotificationDataChanged();
    },
    person() {
      this.onPersonDataChanged();
    },
    // On route change, we need to update the notification, person and video data. To force this
    // update, since we are using the same component, we reset them when the component is created.
    // And to get update from the store when the notification is updated, for example, we keep a
    // reference to the store
    notificationRef() {
      this.notification = this.notificationRef;
    },
  },
  created() {
    this.onNotificationIdChanged();
  },
  methods: {
    ...mapActions({
      fetchPerson: 'faceid/fetchPerson',
      fetchVideo: 'faceid/fetchVideo',
      fetchNotification: 'faceid/fetchNotification',
      fetchSuperProfile: 'faceid/fetchSuperProfile',
      deleteNotification: 'faceid/deleteNotification',
      editNotificationAction: 'faceid/editNotification',
      isImageNameValid: 'faceid/isImageNameValid',
      fetchPersonImages: 'faceid/fetchPersonImages',
      resetPersonImages: 'faceid/resetPersonImages',
      monitorNotification: 'faceid/monitorNotification',
    }),
    resetAlert() {
      this.alert = false;
      this.alertColor = '';
      this.alertMessage = '';
    },
    showAlert(color, message) {
      this.alertColor = color;
      this.alertMessage = message;
      this.alert = true;
    },
    sortHistoryInDescendingOrder(history) {
      history.sort((a, b) => -a.created_at.localeCompare(b.created_at));
    },
    goBack() {
      if (this.fromRoute?.name) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'faceid-notifications' });
      }
    },
    setNotificationImageIndex(index) {
      this.notificationImageIndex = index;
    },
    goToProfile() {
      if (this.isOnDeconveHubWorkspace) {
        return this.$router.push({ name: 'faceid-super-profile', params: { superProfileId: this.person.id } });
      }

      return this.$router.push({ name: 'faceid-person', params: { personId: this.person.id } });
    },
    editNotification(params) {
      this.isEditingNotification = true;
      this.editNotificationAction(params).then(() => {
        this.isEditingNotification = false;
        this.showAlert('primary', this.$t('deconve.notification.alerts.success'));
      }).catch(() => {
        this.isEditingNotification = false;
        this.showAlert('warn', this.$t('deconve.notification.alerts.error'));
      });
    },
    fetchPersonProfileImage() {
      if (this.person) {
        downloadImageHelper(this.person.profile_image_url)
          .then((image) => {
            this.personProfileImage = image;
          });
      } else {
        this.personProfileImage = null;
      }
    },
    removeFileNameExtension(fileName) {
      return fileName.replace(/\.[^/.]+$/, '');
    },
    selectProfileImage(index) {
      this.selectedProfileImageIndex = index;
    },
    downloadImage(index) {
      this.notificationImageIndex = index;
      let notificationImage = this.notificationImages[index];

      const {
        videoNameOriginal,
        faceThumbnail,
        frameThumbnail,
        face,
        frame,
      } = notificationImage;

      // Do not download the image again
      if (faceThumbnail && frameThumbnail && face && frame) return;

      const { notificationId } = this.$route.params;

      this.fetchNotification(notificationId).then((notificationData) => {
        if (notificationData) {
          const { faces } = notificationData;

          // Current image position can change if the notification was updated while we try to read
          // the images
          const newIndex = faces.findIndex(
            (faceInfo) => videoNameOriginal === faceInfo.video_frame.original,
          );

          if (newIndex === index) {
            notificationImage = this.notificationImages[index];
          } else if (newIndex >= 0) {
            this.downloadImage(newIndex);
          } else { // the image was removed
            return;
          }

          const {
            face_image: faceImageInfo,
            video_frame: videoFrameInfo,
          } = notificationData.faces[index];

          const { thumbnail_url: faceThumbnailUrl, original_url: faceOriginalUrl } = faceImageInfo;
          const {
            thumbnail_url: frameThumbnailUrl,
            medium_quality_url: frameMediumQualityUrl,
          } = videoFrameInfo;

          // If the image url is not available, we need to set the notificationImages to let the
          // user to know it
          downloadImageHelper(faceThumbnailUrl).then((image) => {
            Reflect.set(notificationImage, 'faceThumbnail', image);
            this.notificationImages.splice(index, 1, notificationImage);
          });

          downloadImageHelper(frameThumbnailUrl).then((image) => {
            Reflect.set(notificationImage, 'frameThumbnail', image);
            this.notificationImages.splice(index, 1, notificationImage);
          });

          downloadImageHelper(faceOriginalUrl).then((image) => {
            Reflect.set(notificationImage, 'face', image);
            this.notificationImages.splice(index, 1, notificationImage);
          });

          downloadImageHelper(frameMediumQualityUrl).then((image) => {
            // Try to load the original url, if the medium quality frame is not available
            if (image?.length > 0) {
              Reflect.set(notificationImage, 'frame', image);
              this.notificationImages.splice(index, 1, { ...notificationImage, frame: image });
            } else {
              const { original_url: originalVideoFrameUrl } = videoFrameInfo;

              downloadImageHelper(originalVideoFrameUrl).then((originalFrame) => {
                Reflect.set(notificationImage, 'originalFrame', originalFrame);
                Reflect.set(notificationImage, 'frame', image);

                this.notificationImages.splice(
                  index, 1, { ...notificationImage, originalFrame, frame: image },
                );
              });
            }
          });
        }
      });
    },
    organizeImages() {
      if (!this.notification) return;

      const { faces } = this.notification;

      this.notificationImages = faces.map((face) => {
        const { video_frame: videoFrameInfo } = face;

        const {
          original: videoNameOriginal,
          original_height: originalHeight,
          original_width: originalWidth,
        } = videoFrameInfo;

        return {
          videoNameOriginal,
          originalHeight,
          originalWidth,
          info: face.face_bounding_box,
        };
      });

      this.downloadImage(
        this.notificationImageIndex > 0 ? this.notificationImageIndex : this.bestFaceIndex,
      );
    },
    moveNotificationToTrash() {
      this.editNotification({
        id: this.notificationId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        data: { move_to_trash: true },
      });
    },
    restoreNotificationFromTrash() {
      this.editNotification({
        id: this.notificationId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        data: { restore_from_trash: true },
      });
    },
    onNotificationDataChanged() {
      if (!this.notification) return;

      this.isSamePerson = this.notification.is_same_person;
      this.notificationAbout = this.notification.about;

      const { best_face_index: bestFaceIndex, faces } = this.notification;

      this.numberOfImages = faces.length;

      this.bestFaceIndex = bestFaceIndex;
      this.organizeImages();
    },
    onPersonDataChanged() {
      if (!this.person) return;

      const { id: personId, images } = this.person;

      this.fetchPersonProfileImage();
      this.fetchPersonImages({ images, personId });
    },
    onNotificationIdChanged() {
      this.notificationId = this.$route.params.notificationId;

      // To force update when the route changes
      this.person = undefined;
      this.personProfileImage = undefined;
      this.video = undefined;
      this.notification = undefined;
      this.notificationImageIndex = undefined;

      this.resetPersonImages();

      this.fetchNotification(this.notificationId).then((notificationData) => {
        if (notificationData) {
          this.notification = notificationData;
          const { person, super_profile: superProfile, video } = notificationData;

          if (this.isOnDeconveHubWorkspace) {
            this.fetchSuperProfile(superProfile?.id).then((superProfileData) => {
              this.fetchPerson(person?.id).then((personData) => {
                const relatedProfiles = personData.related_profiles;

                // eslint-disable-next-line @typescript-eslint/camelcase
                this.person = { ...superProfileData, related_profiles: relatedProfiles };
                this.fetchPersonProfileImage();
              });
            }).catch(() => {
              this.person = null;
              this.fetchPersonProfileImage();
            });
          } else {
            this.fetchPerson(person?.id).then((personData) => {
              this.person = personData;
              this.fetchPersonProfileImage();
            }).catch(() => {
              this.person = null;
              this.fetchPersonProfileImage();
            });
          }

          this.fetchVideo(video?.id).then((videoData) => {
            this.video = videoData;
          }).catch(() => {
            this.video = null;
          });
        }
      });
    },
    deleteNotificationDialog() {
      this.$refs.confirm
        .open(
          this.$t('deconve.notification.deleteDialog.title'),
          this.$t('deconve.notification.deleteDialog.message'),
        )
        .then((confirm) => {
          if (confirm) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this.deleteNotification(this.notificationId).then(() => this.goBack()).catch(() => {});
          }
        });
    },
    updateIsSamePerson(value) {
      this.isSamePerson = value;
      this.confirmNotification();
    },
    followNotification() {
      this.monitorNotification(this.notificationId);
    },
    confirmNotification() {
      this.editNotification({
        id: this.notificationId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        data: { is_same_person: this.isSamePerson },
      });
    },
    submitNotification() {
      this.editNotification({
        id: this.notificationId,
        data: { about: this.notificationAbout },
      });
    },
    openNotificationsReviewDialog() {
      this.$refs.notificationsReviewDialog.open({
        isSamePerson: this.isSamePerson,
        videoId: this.video?.id,
        personId: this.personId,
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    // Saves the fromRoute info. The component public data is accessed by the `vm` object
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.fromRoute = from;
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.onNotificationIdChanged();
  },
};
</script>

<style>
[role="radiogroup"] {
  flex-direction: column !important;
}

.disablePointEvents {
  pointer-events: none
}

.positiveButtonBackgroundColor {
  background-color: rgb(239, 248, 241) !important;
}

.negativeButtonBackgroundColor {
  background-color: rgb(254, 244, 244) !important;
}

.v-btn--disabled.negativeButtonBackgroundColor {
  border-color: rgb(243, 109, 110) !important;
  background-color: rgb(254, 230, 222) !important;
}

.theme--light.v-btn.v-btn--disabled .v-icon {
  color: rgb(243, 109, 110) !important;
}

.theme--light.v-btn.v-btn--disabled .ml-1 {
  color: rgb(243, 109, 110) !important;
}
</style>
